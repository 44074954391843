/* client/src/styles.css */
/* Reset default styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top: 5px solid #3498db; /* Deep blue color */
  border-bottom: 5px solid #5bc0de; /* Light blue color */
  animation: spin 1s linear infinite; /* Spinning animation */
}
.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0E9AC2 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 13px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

}

*::-webkit-scrollbar-track:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

*::-webkit-scrollbar-track:active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #0E9AC2;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #0B26A3;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #1E74A3;
}

/* Global styles */
body {
  font-family: 'Poppins';
  color: #333;
  background: rgb(247,214,214);
  background: linear-gradient(84deg, rgba(247,214,214,1) 35%, rgba(144,240,248,1) 78%);

  margin: 0;
  padding: 0;
  min-height: 100vh;
}
/* Styles for the homepage */
.home {
  text-align: center;
  padding: 50px;
}

/* .navigation {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  flex: 0 0 40%;
  margin-left: 15px;
} */

.navigation a {
  margin: 0 0 0 0;
  margin: 10px;
  padding: 8px 16px;
  border: 1px solid #333;
  text-decoration: none;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navigation a:hover {
  background-color: #333;
  color: #fff;
}

/* Styles for the NavBar */
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 1rem;
}

.logo a {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

/* Styles for the image carousel */
.image-carousel {
  max-height: 230px; /* Adjust the carousel height */
  backdrop-filter: blur(10px); /* Apply a glass effect */
  border-radius: 10px; /* Round the corners */
  overflow: hidden; /* Hide overflowing content */
  position: relative;
  margin-top: 10px;
}

.image-carousel .carousel .slide img {
  object-fit:cover; /* Prevent image cropping */
  max-width: 100%; /* Set maximum width */
  max-height: 100%; /* Set maximum height */
  width: auto;
  height: auto;
}

.carousel-button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  width: 40px; /* Set a fixed width to make the button completely round */
  height: 40px; /* Set a fixed height to make the button completely round */
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button.prev {
  left: 20px;
  top: calc(50% - 20px); /* Center vertically */
}

.carousel-button.next {
  right: 20px;
  top: calc(50% - 20px); /* Center vertically */
}

/* Styles for the download bar */
.download-bar {
  background-color: #007bff; /* Blue background color */
  color: white; /* Text color */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 20px;
  font-family: 'Your Elegant Font', sans-serif; /* Replace with your desired elegant font */
  border-radius: 35px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}


.download-bar p {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.download-links {
  display: flex; /* Display buttons horizontally */
  gap: 10px; /* Add some spacing between buttons */
}

.download-links a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px; /* Adjust button font size */
}

.download-links span.material-icons {
  font-size: 24px; /* Adjust icon size */
  margin-right: 5px;
  max-width: 24px;
}
.download-links {
  display: flex;
  gap: 10px;
}

.download-links a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition: background-color 0.3s ease; /* Add transition for smooth effect */
  padding: 5px 10px; /* Adjust padding for better button appearance */
  border-radius: 4px; /* Add border-radius for rounded corners */
}

.download-links a:hover {
  background-color: rgba(255, 255, 255, 0.618); /* Light background on hover */
 color: #333;
  }

.download-links span.material-icons {
  font-size: 24px;
  margin-right: 5px;
  max-width: 24px;
}
@media (max-width: 768px) {
  .nav-bar {
    padding: 0.5rem; /* Decrease padding for smaller screens */
  }

  .logo a {
    font-size: 1.2rem; /* Decrease logo font size */
  }

  .nav-links {
    display: none; /* Hide navigation links on small screens */
  }

  .navigation a {
    margin: 5px;
    padding: 6px 12px;
    font-size: 14px; /* Decrease font size for smaller screens */
  }
  .download-links {
    display: flex;
    font-size: 16px; /* Decrease button font size for smaller screens */
    flex-direction: column;}
.linkText{
  display: none;
 }

}
@media (max-width: 600px) {
  /* Further adjustments for smaller screens */


  .download-bar p {
    font-size: 16px; /* Decrease font size for even smaller screens */
  }

  .download-links a {
    font-size: 16px; /* Decrease button font size for smaller screens */
  }
}
.plan-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.plan-card {
  
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

/* Add media query for responsive adjustments */
@media (max-width: 768px) {
  .plan-list {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .plan-card {
    padding: 15px;
  }
}

/* client/src/pages/ViewPlans.css */
.cards {
  margin: 80px auto;
  max-width: 960px;
  text-align: center;
}

.card {
  background: #fff;
  display: inline-block;
  margin: 8px;
  max-width: 300px;
  perspective: 1000;
  position: relative;
  text-align: left;
  transition: all 0.3s ease-in;
  width: 300px;
  z-index: 1;
}
  img {
    max-width: 300px;
  }

  .card__image-holder {
    background: rgba(0, 0, 0, 0.1);
    height: 0;
    padding-bottom: 75%;
  }

  .card-title {
    background: #fff;
    padding: 6px 15px 10px;
    position: relative;
    z-index: 0;

    .toggle-info {
      border-radius: 32px;
      height: 32px;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 10px;
      width: 32px;

      span {
        background: #fff;
        display: block;
        height: 2px;
        position: absolute;
        top: 16px;
        transition: all 0.15s ease-out;
        width: 12px;
      }

      .left {
        right: 14px;
        transform: rotate(45deg);
      }
      .right {
        left: 14px;
        transform: rotate(-45deg);
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      padding: 0;

      small {
        display: block;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.025em;
      }
    }
  }

/* client/src/pages/ViewPlans.css */
.view-plans {
  text-align: center;
  height: 100vh;
}

.carousel1 {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 80%;
  margin: 20px auto;
}

.carousel1-inner {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: calc(100% + 15px);
  gap: 5px;
}


.plan-card {
  flex: 0 0 33.33%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: transform 0.3s;
  height: 500px; /* Set the card height to 300px */
  border-radius: 8px;
   background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border:1px #000;
  width: 80%;
}
.planName{
  width: 100%;
  height:20%;
  background-color: #0056b3;
  padding: 5px 10px; /* Add some padding to the blue box */
  border-radius: 8px; 
  align-items: center;
}
.plan-card h3 {
  margin: 0;
  font-weight: lighter;
  color: #fff; /* Change the text color to white */
}
.plan-card p {
  margin: 5px 0;
}

.view-details-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  align-self: center;
}

.view-details-btn:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.carousel1-nav {
  margin-top: 10px;
}

.carousel1-nav button {
  padding: 6px 12px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100px;
}


/* client/src/components/PlanDetailsPopup.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.plan-details-popup {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  position: relative;
  padding-top: 40px; /* Add extra padding to the top */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.purchase-button{
  background-color: #007bff; /* Set button background color to blue */
  color: #fff; /* Set button text color to white */
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.plan-details-popup h3 {
  margin: 0;
  font-size: 20px;
  font-weight: lighter;
  color: #007bff; /* Set text color to blue */
  padding: 10px 0; /* Add padding to the top of the modal */
  border-bottom: 1px solid #ccc; /* Add a border to separate the header */
}

.plan-details-popup p,li {
  margin: 5px 0;
  text-align: start;
}


.purchase-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* client/src/pages/Signup.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Signup.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  background-image: url('./assets/bsnl-fullsc.jpg');
  background-size:cover; /* Change this value */
  background-position: center;
  background-repeat: no-repeat;
}

.login-register-container {
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.login-register-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-field-wrapper {
  margin-bottom: 15px;
}

.formlabel {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  transition: border-bottom-color 0.3s ease;
}

.input-field:focus {
  border-bottom-color: #007bff;
}

.input-field-password {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.error-message {
  color: red;
  margin: 10px 0;
}
.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: #0056b3;
}

.authLink {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.gradient-text {
  background: linear-gradient(90deg, #007bff, #0099ff);
  -webkit-background-clip: text;
  color: transparent;
  transition: background-position 0.3s ease;
}

.gradient-text:hover {
  background-position: right;
}

.right-password {
  border-bottom-color: #2ecc71 !important;
}

.wrong-password {
  border-bottom-color: #e74c3c !important;
  animation: shake 0.3s linear;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}



/* .testimonial-section {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  flex: 0 0 60%;
} */

.testimonial-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.testimonial-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rating span {
  font-size: 18px;
  margin-right: 10px;
}
.star-container {
  display: inline-block;
  margin-left: 10px;
}
.star-container input[type="radio"] {
  display: none;
}

.star {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.star-icon.filled {
  color: gold; /* Color for filled stars */
}
textarea {
  width: 100%;
  padding: 10px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 8px;
  font-size: 16px;
  resize: vertical;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
/* .homeContainer {
  display: flex;
  width: 100%;
} */
.homeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  margin-top: 20px;
}

.testimonial-section {
  grid-area: 1 / 2 / 3 / 3;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.navigation {
  grid-area: 1 / 1 / 2 / 2;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.track-complain {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  
}

.track-complain h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
}
.track-complain p {
  margin-top: 5px;
}
.track-complain input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #6f6969;
  transition: border-color 0.3s ease;
  text-align: center;
}

.track-complain input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
}

.track-complain button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.track-complain button:hover {
  background-color: #0056b3;
}
.track-complain .cancel-button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #e61e2f;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.track-complain .cancel-button:hover {
  background-color: #730707;
}
/* Progress Bar Styles */
.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.node {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #030303;
  position: relative;
  transition: color 0.3s ease;
}

.node.active {
  color: #00c853; /* Change the color for active nodes */
}

/* Cancel Button */
.track-complain button.cancel {
  background-color: #d32f2f;
  margin-top: 10px;
}

.track-complain button.cancel:hover {
  background-color: #b71c1c;
}
/* Modal content */
.payment-status-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* Confirmation message */
.confirmation-message,
.error-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.confirmation-message {
  color: #4caf50;
}

.error-message {
  color: #ff5722;
}

/* Ensure the text wraps within the modal */
.confirmation-message,
.error-message {
  white-space: pre-line;
}

/* Styling for the copy button */
.copy-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.copy-button:hover {
  background-color: #45a049;
}

/* Hide the textarea used for copying
textarea {
  position: absolute;
  top: -9999px;
} */


.copied-popup {
  background-color: grey;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.register-complaint {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.register-complaint h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.register-complaint .success-message,
.register-complaint .error-message {
  font-size: 16px;
  margin-bottom: 10px;
  color: green; /* Success message color */
}

.register-complaint .error-message {
  color: red; /* Error message color */
}

.register-complaint .complaint-form label {
  font-size: 16px;
  margin-top: 10px;
}

.register-complaint .complaint-form select,
.register-complaint .complaint-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
}

.register-complaint .complaint-form textarea {
  resize: vertical;
  height: 100px;
}

.register-complaint .complaint-form input[type="file"] {
  margin-top: 10px;
}

.register-complaint .image-preview {
  margin-top: 10px;
  display: flex;
  align-items: center;
}


.register-complaint .image-preview button {
  font-size: 14px;
  padding: 5px 10px;
  background-color: #e74c3c; /* Button color */
  color: white;
  border: none;
  cursor: pointer;
}

.register-complaint .complaint-form button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #3498db; /* Button color */
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.register-complaint .complaint-form button:hover {
  background-color: #11650b; /* Button hover color */
}
.register-complaint .complaint-form .cancelButton {
  font-size: 8px;
  padding: 5px 10px;
  background-color: #d91717; /* Button color */
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.register-complaint .complaint-form .cancelButton:hover {
  background-color: #f70808; /* Button hover color */
}
.required {
  color: red;
}

/* Add this CSS to your existing styles or create a new CSS file */
.upload-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  background-color: #efa95f;
  color: rgb(8, 8, 8);
  border: 1px orangered;
  border-radius: 4px;
  cursor: pointer;
  
}

.upload-icon {
  width: 18px;
  height: 18px;
}

.image-preview {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
  border:1px #000;
}

/* Optional: Style the input[type="file"] */
.input-file {
  display: none;
}

.fixed-button {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

/* ProfilePage.css */
.profile-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.profile-details {
  margin-bottom: 20px;
  text-align: left;
}

.profile-details p {
  font-size: 18px;
  margin: 8px 0;
}

.profile-form .form-field {
  margin-bottom: 15px;
  text-align: left;
}

.profile-form label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-form input[type="text"],
.profile-form input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  margin-top: 20px;
}

.form-actions button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.form-actions button:last-child {
  margin-right: 0;
}

.form-actions button:hover {
  background-color: #0056b3;
}

/* Style the Add Plan Form */
.dashboard-structure {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  margin: 20px;
}

.AddPlanForm {
  grid-area: 1 / 1 / 3 / 2;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* .PlansEdit {
  grid-area: 1 / 2 / 3 / 3;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
} */


.admin-dashboard {
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Styling for form elements */
.form-label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  max-width: 50%;
  margin-left: 10px;
}

/* Styling for buttons */
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3; /* Hover color for the button */
  transform: translateY(-3px);
}


.admin-heading {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically center the items */
  padding: 20px; /* Add padding for spacing */
}

.admin-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.admin-heading p{
  font-size: 15px;
  font-weight: bold;
  color: #747474;
  margin: 0;
}
.admin-heading h1 {
  margin: 0; /* Remove default margin */
}

.session-info {
  text-align: center;
}

.logout-btn {
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: #d64112;
}


/* Styling for the ComplaintManager component */

.Complaint-Edit {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 500px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content overflows */
}
.Plans-Manager{
  grid-area: 2 / 2 / 2 / 3;
  position: relative;
  background-color: rgba(14, 139, 60, 0.045);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 500px; /* Adjust the max height as needed */
  overflow-y: auto; 
}

/* Header styles */
.Complaint-Edit h1 {
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}
.Plans-Manager h1{
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}

/* Table styles */
.ComplaintEditTable {
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content overflows */
}
.PlansManagerTable {
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto; /* Add vertical scroll if content overflows */
}

.scrollable-table {
  width: 100%; /* Set the table width to 100% */
  border-collapse: collapse;
  border-spacing: 0;
}

.scrollable-table th,
.scrollable-table td {
  padding: 8px; /* Adjust cell padding as needed */
  text-align: left;
  border-bottom: 1px solid #ddd; /* Add border-bottom for table rows */
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #0e8b3c; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  z-index: 1; /* Ensure the headings are above the table content */
}
/* Styling for the ComplaintEditModal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.admin-modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 300px;
}
.admin-modal-2 {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 500px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 18px;
  line-height: 1;
  padding: 0;
  color: #333;
}
.modal-button{
  margin-right: 20px;
  background-color: rgb(247, 80, 80);
}
.modal-button :hover{
  background-color: red;
}
h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
h1{
  margin-bottom: 2px;
}

p {
  font-size: 16px;
  margin: 10px 0;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

button {
  background-color: #0e8b3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 16px;
}



/* Styling for the table in the ComplaintManager component */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #0e448b; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  z-index: 1; /* Ensure the headings are above the table content */
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table th,
table td {
  text-align: left;
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #0e8b3c;
  color: #fff;
}

/* Styling for the OPEN button */
button.open-button {
  background-color: #0e8b3c;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

button.open-button:hover {
  background-color: #0c7533;
}

